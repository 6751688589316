import { useState, useEffect } from 'react'
import './style.css'
import logo from '../../assets/image/registered-member/logosCdcWhiteAllFit.png'


import octopusLogo from '../../assets/image/registered-member/logosOctopus.png'
import iconNavInfo from '../../assets/image/registered-member/iconNavInfo.png'
import iconNeumorphismArrowRight from '../../assets/image/registered-member/iconNeumorphismArrowRight.png'
import iconGamificationCoin from '../../assets/image/registered-member/iconGamificationCoin.png'
import iconPopupPromoCode from '../../assets/image/registered-member/iconPopupPromoCode.png'
import iconNavClose from '../../assets/image/registered-member/iconNavClose.png'

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as CONSTANT from '../../constant';
import { redirectToAppStore } from '../../util/common';

const Dialog = (props) => {
  const {backHandle,dialogFlag} =props
  console.log(props);
  const { t } = useTranslation();
  const handleDialogContext=()=>{

    switch (dialogFlag) 
    {
      case 'Consumption': 
      return {
        title:'how_to_earn_privilege_2_popup_header',
        top: 'how_to_earn_privilege_2_popup_description',
        buttom: 'how_to_earn_privilege_2_popup_description_time',
        footer: 'how_to_earn_privilege_2_popup_description_remarks'
      };
      case 'Extra' :
      return {
        title:'how_to_earn_privilege_3_popup_header',
        top: 'how_to_earn_privilege_3_popup_description',
        buttom: 'how_to_earn_privilege_3_popup_description_time',
      };
      case 'Share' :
      return {
        title:'how_to_earn_privilege_5_popup_header',
        top: 'how_to_earn_privilege_5_popup_description',
      };
      case 'NewGift' :
      return {
        title:'how_to_register_step_3_popup_header',
        top: 'how_to_register_step_3_popup_description',
        footer: 'how_to_register_step_3_popup_description_remarks'
      };
      default:
        return;
    }
   
  }
  return (
   <div>
    <div className='con-wrapper'>
      <div className='wrapper-body'>
        <img src={iconNavClose} alt="iconNavClose" className='icon-close' onClick={backHandle}/>
        <img src={iconPopupPromoCode} alt="iconPopupPromoCode" className='head-img' />

        <h1 className='con-head'>{t(handleDialogContext().title)}</h1>
        <div className='wrapper-content'>
          <div className='top'>
            {/* {t(`how_to_earn_privilege_2_popup_description`)} */}
            {t(handleDialogContext().top)}
          </div>
          <div className='bottom'>
            {t(handleDialogContext().buttom)}
          </div>
        </div>
           <div className='wrapper-footer'>{t(handleDialogContext().footer)}</div>
      </div>
    </div>
   </div>
  )


}


const Home = () => {

  const [ShowDialog, setShowDialog] = useState(false);
  const [dialogFlag,setDialogFlag]=useState('Dialog')
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const registerUrl = CONSTANT.DEEPLINK_BASE_URL + "app/register";

  const onEvent = (dialog) => {
    setDialogFlag(dialog)
    setShowDialog(true);
  }
  const backHandle=()=>{
    setShowDialog(false)
  }
  
  useEffect(() => {
      if (lang === 'en') {
          i18n.changeLanguage('en');
      } else { 
          i18n.changeLanguage('zh');
      }
  }, [lang, i18n]);

  useEffect(() => {
    const pathname = window.location.pathname;
  
    if (pathname !== '/' && pathname !== '/en' && pathname !== '/zh') {
      redirectToAppStore();
    }
    
  }, []);

  return (
    <div className="contianer">
      <div>
        {ShowDialog && (<Dialog backHandle={backHandle} dialogFlag={dialogFlag} /> )}
        <div lang={lang} className="reg-body">
          <div className='body-header'>
            <img src={logo} alt="logo" className='logo' />
            <h2 lang={lang} className='top-title'>
              {t('how_to_earn_header1')}
              <br/>
              {t('how_to_earn_header2')}
            </h2>
            <a href={registerUrl}><div lang={lang} className='vip-btn action-button'>{t('how_to_earn_register')}</div></a>
          </div>
          <div className='body-content'>
            {/* {m-upgrade} */}
            <div>
              <div className='m-upgrade-content'>{t('member_upgrade_content')}
              </div>
            </div>

            {/* {b-upgrade} */}
            <div>
              <h2 lang={lang} className='b-upgrade-title'>{t('birthday_upgrade_title')}</h2>
              <div className='b-upgrade-content'>{t('birthday_upgrade_content')}
              </div>
            </div>

            {/* {more} */}
            <div>
              <h2 lang={lang} className='more-title'>{t('more_title')}</h2>
              <div className='more-content'>{t('more_content')}
              </div>
            </div>
            
            {/* {e-coupon} */}
            <div className='e-coupon'>
              <h2 lang={lang} className='title'>{t('how_to_earn_privilege_1_header')}</h2>
              <div className='content'>{t('how_to_earn_privilege_1_description')}
              </div>
              {/* <span className='remark'>{t('how_to_earn_privilege_1_tnc')}</span> */}
            </div>

            {/* consumption */}
            <div className='consumption'>
              <h2 lang={lang} className='con-title'>{t('how_to_earn_privilege_2_header')}</h2>
              <div lang={lang} className='con-content'>
                {t('how_to_earn_privilege_2_description')}
                <span >
                  <img src={iconNavInfo} alt="iconNavInfo" className='inconNav' onClick={() => { onEvent('Consumption') }}/>
                </span>
              </div>
              <div className='con-remark'>
                <div lang={lang} className='cale'>{t('how_to_earn_privilege_2_description_calculation')}</div>
                <div lang={lang} className='three'>
                  {t('how_to_earn_privilege_2_description_calculation_3_times')} {lang === 'en' ? <br/> : ''} <img src={iconNeumorphismArrowRight} alt="iconNeumorphismArrowRight" className='iconNeumorphismArrowRight' />
                  {t('how_to_earn_privilege_2_description_calculation_3_times_reward')}<img src={iconGamificationCoin} alt="iconGamificationCoin" className='iconGamificationCoin' />
                </div>
                <div lang={lang} className='five'>
                  {t('how_to_earn_privilege_2_description_calculation_5_times')} {lang === 'en' ? <br/> : ''} <img src={iconNeumorphismArrowRight} alt="iconNeumorphismArrowRight" className='iconNeumorphismArrowRight' />
                  {t('how_to_earn_privilege_2_description_calculation_5_times_reward')}<img src={iconGamificationCoin} alt="iconGamificationCoin" className='iconGamificationCoin' />
                </div>

              </div>
            </div>

            {/* extra */}
            <div className='extra'>
              <h2 lang={lang} className='extra-title'>{t('how_to_earn_privilege_3_header')}</h2>
              <div className='extra-content'>
                {t('how_to_earn_privilege_3_description')}
              </div>
              <div className='extra-remark'>{t('how_to_earn_privilege_3_description_calculation')} 
                <img src={iconNavInfo} alt="iconNavInfo" className='inconNav' onClick={() => { onEvent('Extra') }}/>
              </div>
            </div>

            {/* birthday */}
            <div className='birthday'>
              <h2 lang={lang} className='birthday-title'>{t('how_to_earn_privilege_4_header')}</h2>
              <div className='birthday-content'>
                {t('how_to_earn_privilege_4_description')}
              </div>
            </div>

            {/* 分享 */}
            <div className='share'>
              <h2 lang={lang} className='share-title'>{t('how_to_earn_privilege_5_header')}</h2>
              <div className='share-content'>
                {t('how_to_earn_privilege_5_description')}
                <span>
                  <img src={iconNavInfo} alt="iconNavInfo" className='inconNav' onClick={() => { onEvent('Share') }}/>
                  </span>
              </div>
            </div>

            {/* register */}
            <div className='register'>
              <p>{t('how_to_earn_scoll_how_to_register')}</p>

            </div>
            <div className='res-one'>
              <div className='left1'>
                <span className='num0'>0</span>
                <span className='num1'>1</span>
              </div>
              <div className='right'>
                <h2 lang={lang} className='right1-title'>{t('how_to_register_step_1_header')}</h2>
                <div className='right1-content'>{t('how_to_register_step_1_description')}</div>
              </div>
            </div>

            <div className='res-two'>
              <div className='left2'>
                <span className='num0-2'>0</span>
                <span className='num1-2'>2</span>
              </div>
              <div className='right2'>
                <h2 lang={lang} className='right2-title'>{t('how_to_register_step_2_header')}</h2>
                <div className='right2-content'>{t('how_to_register_step_2_description')}</div>
              </div>
            </div>

            <div className='res-three'>
              <div className='left3'>
                <span className='num0-3'>0</span>
                <span className='num1-3'>3</span>
              </div>
              <div className='right3'>
                <h2 lang={lang} className='right3-title'>{t('how_to_register_step_3_header')}</h2>
                <div className='right3-content'>{t('how_to_register_step_3_description')}
                  <span>
                    <img src={iconNavInfo} alt="iconNavInfo" className='inconNav' onClick={() => { onEvent('NewGift') }}/>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*footer */}
        <div className="footer">
          <a href={registerUrl}><div lang={lang} className='vip-footer-btn action-button'>{t('how_to_register_register_now')}</div></a>
          <a href={registerUrl}><div lang={lang} className='vip-footer-btn activation-button'>{t('how_to_register_member_activation')}<img className='octopus-icon' src={octopusLogo} alt="" /></div></a>
        </div>
      </div>
    </div>  
  );
}

export default Home;